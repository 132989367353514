<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <ValidationProvider name="Division" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('warehouse_information.division') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="bankLoanFacility.division_id"
                      :options="divisionList"
                      id="division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('warehouse_information.district') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="bankLoanFacility.district_id"
                      :options="districtList"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Upazila" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazilla_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('warehouse_information.upazilla') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="bankLoanFacility.upazilla_id"
                      :options="upazilaList"
                      id="upazilla_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                    <ValidationProvider name="Warehouse Name" vid="warehouse_name" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="warehouse_name"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_config.warehouse_name')}} <span class="text-danger">*</span>
                         </template>
                          <b-form-select
                          plain
                          v-model="bankLoanFacility.warehouse_id"
                          :options="warehouseNameList"
                          id="warehouse_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>

                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                </ValidationProvider>
                 <ValidationProvider name="Bank name" vid="bank_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="bank_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.bank_name')}} <span class="text-danger">*</span>
                         </template>
                          <b-form-select
                          plain
                          v-model="bankLoanFacility.bank_id"
                          :options="bankList"
                          id="bank_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                </ValidationProvider>
                  <ValidationProvider name="Branch Name" vid="branch_id">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="branch_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.branch_name')}}
                         </template>
                          <b-form-select
                          plain
                          v-model="bankLoanFacility.branch_id"
                          :options="branchList"
                          id="branch_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>

                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                </ValidationProvider>
                    <!-- <ValidationProvider name="Branch Name">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="branch_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.branch_name')}}
                         </template>
                        <b-form-input
                          id="branch_id"
                          v-model="bankLoanFacility.branch_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider> -->
                    <ValidationProvider name="Interest Rate" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="interest_rate"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.interest_rate')}} <span class="text-danger">*</span>
                         </template>
                        <b-form-input
                          id="interest_rate"
                          v-model="bankLoanFacility.interest_rate"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Fiscal Year" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.fiscal_year')}} <span class="text-danger">*</span>
                         </template>
                        <b-form-input
                          id="fiscal_year_id"
                          v-model="bankLoanFacility.fiscal_year_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="File Name (Bn)">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="file_name"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.file_name_en')}}
                         </template>
                        <b-form-input
                          id="file_name"
                          v-model="bankLoanFacility.file_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                     <ValidationProvider name="File Name (bn)">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="file_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.file_name_bn')}}
                         </template>
                        <b-form-input
                          id="file_name_bn"
                          v-model="bankLoanFacility.file_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                     <ValidationProvider name="Attached file" vid="attachment">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="attachment"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.attached_file')}}
                         </template>
                        <b-form-file
                          id="attachment"
                          v-on:change="onFileChange"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Remarks" vid="remarks">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="remarks"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.remarks')}}
                         </template>
                          <b-form-input
                          plain
                          type="text"
                          v-model="bankLoanFacility.remarks"
                          id="remarks"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-input>

                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                </ValidationProvider>
                 <ValidationProvider name="Remarks" vid="remarks">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="remarks_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_information.remarks_Bn')}}
                         </template>
                          <b-form-input
                          plain
                          type="text"
                          v-model="bankLoanFacility.remarks_bn"
                          id="remarks_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-input>

                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseBanlLoanFacilityStore, warehouseBanlLoanFacilityUpdate } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getBankLoanFacilityData()
      Object.freeze(tmp)
      this.bankLoanFacility = tmp
    }
    // this.getBanklist()
    //  this.getWarehouseName()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      bankLoanFacility: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        warehouse_id: 0,
        bank_id: 0,
        branch_id: 0,
        interest_rate: '',
        fiscal_year_id: 0,
        file_name: '',
        file_name_bn: '',
        remarks: '',
        remarks_bn: '',
        attachment: []
      },
      attachment_two: [],
      districtList: [],
      upazilaList: [],
      categoryList: [],
      warehouseNameList: [],
      branchList: []
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    bankList: function () {
      return this.$store.state.commonObj.bankObj.bankList
    },
    ...mapGetters({
      commonObj: 'commonObj'
    })
  },
  watch: {
     'bankLoanFacility.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'bankLoanFacility.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'bankLoanFacility.upazilla_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal)
    },
    'bankLoanFacility.bank_id': function (newVal, oldVal) {
      this.branchList = this.getBranchList(newVal)
    }
  },
  methods: {
    getBankLoanFacilityData () {
      return this.$store.state.WarehouseInfo.warehouseBankLoanFacilitys.find(item => item.id === this.id)
    },
    onFileChange (e) {
      this.attachment_two = e.target.files[0]
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.bankLoanFacility).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.attachment_two)
        } else {
          formData.append(key, this.bankLoanFacility[key])
        }
      })
      if (this.bankLoanFacility.id) {
        result = await RestApi.putData(warehouseServiceBaseUrl, `${warehouseBanlLoanFacilityUpdate}/${this.id}`, formData)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, warehouseBanlLoanFacilityStore, formData, config)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Update successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getWarehouseName (categoryId) {
      RestApi.getData(warehouseServiceBaseUrl, 'master-warehouse-info/list').then(response => {
        const dataObject = response.data.data.filter(subCategory => subCategory.upazilla_id === categoryId)
        this.warehouseNameList = dataObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.warehouse_name_bn }
          } else {
            return { value: obj.id, text: obj.warehouse_name }
          }
        })
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getBranchList (bankId = null) {
      if (bankId) {
        return this.commonObj.bankObj.branchList.filter(item => item.bank_id === bankId)
      }
      return []
    }
  }
}

</script>
