export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'
// warehouse Information start

// warehouse service strat
export const warehouseServiceList = '/master-warehouse-service/list'
export const warehouseServiceStore = '/master-warehouse-service/store'
export const warehouseServiceUpdate = '/master-warehouse-service/update'
export const warehouseServiceListToggleStatus = '/master-warehouse-service/toggle-status'
export const warehouseServiceListDestroy = '/master-warehouse-service/destroy'

// warehouse service strat
export const warehouseBanlLoanFacilityList = '/master-bank-loan/list'
export const warehouseBanlLoanFacilityStore = '/master-bank-loan/store'
export const warehouseBanlLoanFacilityUpdate = '/master-bank-loan/update'
export const warehouseBanlLoanFacilityToggleStatus = '/master-bank-loan/toggle-status'
export const warehouseBanlLoanFacilityListDestroy = '/master-bank-loan/destroy'
